export const libraryActionTypes = {
  FETCH_LIBRARY_START: 'FETCH_LIBRARY_START',
  FETCH_LIBRARY_SUCCESS: 'FETCH_LIBRARY_SUCCESS',
  FETCH_LIBRARY_FAILED: 'FETCH_LIBRARY_FAILED',

  UPDATE_LIBRARY_START: 'UPDATE_LIBRARY_START',
  UPDATE_LIBRARY_SUCCESS: 'UPDATE_LIBRARY_SUCCESS',
  UPDATE_LIBRARY_FAILED: 'UPDATE_LIBRARY_FAILED',

  POST_LIBRARY_START: 'POST_LIBRARY_START',
  POST_LIBRARY_SUCCESS: 'POST_LIBRARY_SUCCESS',
  POST_LIBRARY_FAILED: 'POST_LIBRARY_FAILED',

  DELETE_LIBRARY_START: 'DELETE_LIBRARY_START',
  DELETE_LIBRARY_SUCCESS: 'DELETE_LIBRARY_SUCCESS',
  DELETE_LIBRARY_FAILED: 'DELETE_LIBRARY_FAILED',

  HIDE_ALERT: 'HIDE_ALERT',
};
