import { userActionTypes } from './user.type';
import { removeTrailingSpace } from '../../components/utils/misc';
import api from '../../common/api';

const API_URL = api.apiUrl;

//sets currentUser when user is signing in or register for first time only
export const setCurrentUser = (user) => {
  return {
    type: userActionTypes.SET_CURRENT_USER,
    payload: user,
  };
};

export const setTempUser = (profile) => {
  return {
    type: userActionTypes.SET_TEMP_USER,
    payload: profile,
  };
};

export const fetchUserStart = () => {
  return {
    type: userActionTypes.FETCH_USER_START,
  };
};

export const fetchUserSuccess = (user) => {
  return {
    type: userActionTypes.FETCH_USER_SUCCESS,
    payload: removeTrailingSpace(user),
  };
};

export const fetchUserFailed = (errorMessage) => {
  return {
    type: userActionTypes.FETCH_USER_FAILED,
    payload: errorMessage,
  };
};

//instantiate user fetch from app.js componentDidMount(), gets the user if token is present otherwise the user has to login using signIn Component
export const fetchUserStartAsync = (history, location) => {
  return (dispatch) => {
    const token = window.localStorage.getItem('token');
    if (token) {
      dispatch(fetchUserStart());

      return fetch(`${API_URL}/signin`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.leid) {
            return fetch(`${API_URL}/profile/${data.leid}`, {
              method: 'get',
              headers: {
                'Content-Type': 'application/json',
                Authorization: token,
              },
            })
              .then((resp) => resp.json())
              .then((leProfile) => {
                if (leProfile) {
                  dispatch(fetchUserSuccess(leProfile));
                }
              })
              .catch((err) => dispatch(fetchUserFailed(err.message)));
          }
        })
        .catch((err) => dispatch(fetchUserFailed(err.message)));
    }
  };
};
