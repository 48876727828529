import { leActionTypes } from './le.type';

const LE_HISTORY_INITIAL_STATE = {
  isLeHistoryFetching: false,
  profile: null,
  profileErrorMessage: null,
  isLeProfilesFetching: false,
  profiles: null,
  profilesErrorMessage: null,
  leHistory: null,
  errorMessage: null,
  isLesHistoryFetching: false,
  lesHistory: null,
  lesHistoryErrorMessage: null,
  user: null,
  loading: true,
};

const leReducer = (state = LE_HISTORY_INITIAL_STATE, action) => {
  switch (action.type) {
    //fetch individual le
    case leActionTypes.LE_FETCH_START:
      return {
        ...state,
        isLeHistoryFetching: true,
      };
    case leActionTypes.LE_FETCH_SUCCESS:
      return {
        ...state,
        leHistory: action.payload,
        isLeHistoryFetching: false,
      };
    case leActionTypes.LE_FETCH_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isLeHistoryFetching: false,
      };
    //fetch all le's under same district only if the user is logged in as DL or PD
    case leActionTypes.LES_FETCH_START:
      return {
        ...state,
        isLesHistoryFetching: true,
      };
    case leActionTypes.LES_FETCH_SUCCESS:
      return {
        ...state,
        lesHistory: action.payload,
        isLesHistoryFetching: false,
      };
    case leActionTypes.LES_FETCH_FAILED:
      return {
        ...state,
        lesHistoryErrorMessage: action.payload,
        isLesHistoryFetching: false,
      };

    //fetch le profile
    case leActionTypes.FETCH_LE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };

    case leActionTypes.FETCH_LE_PROFILE_FAILED:
      return {
        ...state,
        profileErrorMessage: action.paylaod,
      };

    //fetch all le profiles
    case leActionTypes.FETCH_LE_PROFILES_START:
      return {
        ...state,
        isLeProfilesFetching: true,
      };
    case leActionTypes.FETCH_LE_PROFILES_SUCCESS:
      return {
        ...state,
        profiles: action.payload,
        isLeProfilesFetching: false,
      };

    case leActionTypes.FETCH_LE_PROFILES_FAILED:
      return {
        ...state,
        profilesErrorMessage: action.paylaod,
        isLeProfilesFetching: false,
      };
    case leActionTypes.LE_FETCH_USER:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default leReducer;
