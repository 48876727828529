import React, { useEffect } from 'react';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ScrollUpButton from 'react-scroll-up-button';
import { ThemeProvider } from '@material-ui/core/styles';

import ModalPopup from './components/diaglog/modal-popup';
import Modal from './components/diaglog/modal';

import { fetchUserStartAsync, setCurrentUser, setTempUser } from './redux/user/user.action';
import { selectIsUserLoggedIn, selectCurrentUser, selectTempUser } from './redux/user/user.selector';
import { selectShowModal } from './redux/modal/modal.selector';

import Routes from './routes';
import theme from './theme';

import './App.css';
import { fetchLeProfilesStartAsync, fetchLeProfileStartAsync } from 'redux/le/le.action';
import { selectLeProfile } from 'redux/le/le.selector';
import { fetchCustomerStartAsync } from 'redux/customer/customer.action';

// class App extends React.Component {
//   async componentDidMount() {
//     const { currentUser, history, location, fetchUser, fetchLeProfile, leProfile, setTempUser,  } = this.props;
//     fetchUser(history, location);
//     fetchLeProfile(currentUser.leid);
//   }

//   render() {
//     console.log(this.props)
//     return (
//         <div className="app">
//           {this.props.showModal && (
//             <Modal>
//               <ModalPopup />
//             </Modal>
//           )}
//           <NotificationContainer />
//           <Router>
//             <Routes/>
//           </Router>
//           <ScrollUpButton />
//         </div>
//       )
//   }
// }

const App = (props) => {
  const {
    currentUser,
    history,
    location,
    fetchUser,
    fetchLeProfile,
    fetchLeProfiles,
    leProfile,
    setTempUser,
    fetchCustomerActivity,
  } = props;

  useEffect(() => {
    fetchUser(history, location);
    if (currentUser.leid) {
      fetchLeProfile(currentUser.leid);
      fetchCustomerActivity(currentUser.leid);
    }
  }, [currentUser.leid, fetchUser, fetchLeProfile, fetchCustomerActivity]);

  useEffect(() => {
    if (currentUser.leaderStatus === 'Publishing Director') {
      fetchLeProfiles();
    }
  }, [currentUser.leaderStatus, fetchLeProfiles]);

  useEffect(() => {
    // fetchCustomerActivity(currentUser.leid)
    setTempUser(leProfile);
  }, [currentUser.leid, leProfile, setTempUser]);

  return (
    <ThemeProvider theme={theme}>
      <div className="app">
        {props.showModal && (
          <Modal>
            <ModalPopup />
          </Modal>
        )}
        <NotificationContainer />
        <Router>
          <Routes />
        </Router>
        <ScrollUpButton />
      </div>
    </ThemeProvider>
  );
};
const mapStateToProps = createStructuredSelector({
  isLoggedIn: selectIsUserLoggedIn,
  currentUser: selectCurrentUser,
  tempUser: selectTempUser,
  showModal: selectShowModal,
  leProfile: selectLeProfile,
});

const mapDispatchToProps = (dispatch) => ({
  // setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  fetchUser: (history, location) => dispatch(fetchUserStartAsync(history, location)),
  fetchLeProfile: (leid) => dispatch(fetchLeProfileStartAsync(leid)),
  setTempUser: (leProfile) => dispatch(setTempUser(leProfile)),
  fetchLeProfiles: () => dispatch(fetchLeProfilesStartAsync()),
  fetchCustomerActivity: (leid) => dispatch(fetchCustomerStartAsync(leid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
