import { leActionTypes } from './le.type';
import { removeTrailingSpace } from '../../components/utils/misc';
import api from '../../common/api';

const API_URL = api.apiUrl;

// fetch individual le's based on their id

const fetchLeHistoryStart = () => {
  return {
    type: leActionTypes.LE_FETCH_START,
  };
};

const fetchLeHistorySuccess = (leHistory) => {
  return {
    type: leActionTypes.LE_FETCH_SUCCESS,
    payload: removeTrailingSpace(leHistory),
  };
};

const fetchLeHistoryFailed = (errorMessage) => {
  return {
    type: leActionTypes.LE_FETCH_FAILED,
    payload: errorMessage,
  };
};

export const fetchLeHistoryStartAsync = (leid) => {
  const token = window.localStorage.getItem('token');

  return (dispatch) => {
    dispatch(fetchLeHistoryStart());
    fetch(`${API_URL}/le-dashboard/${leid}`, {
      method: 'get',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: token,
      },
    })
      .then((resp) => resp.json())
      .then((leHistory) => {
        leHistory[0].LEID && dispatch(fetchLeHistorySuccess(removeTrailingSpace(leHistory)));
      })
      .catch((err) => dispatch(fetchLeHistoryFailed(err.errorMessage)));
  };
};

//fetch all le's under same district

const fetchLesHistoryStart = () => {
  return {
    type: leActionTypes.LES_FETCH_START,
  };
};

const fetchLesHistorySuccess = (leHistory) => {
  return {
    type: leActionTypes.LES_FETCH_SUCCESS,
    payload: removeTrailingSpace(leHistory),
  };
};

const fetchLesHistoryFailed = (errorMessage) => {
  return {
    type: leActionTypes.LES_FETCH_FAILED,
    payload: errorMessage,
  };
};

// const fetchHistoryOfLesUnderDiffDlsStart = () => {
//   return {
//     type: leActionTypes.FETCH_HISTORY_OF_LES_UNDER_DIFF_DLS_START,
//   };
// };

// const fetchHistoryOfLesUnderDiffDlsSuccess = (leHistory) => {
//   return {
//     type: leActionTypes.FETCH_HISTORY_OF_LES_UNDER_DIFF_DLS_SUCCESS,
//     payload: removeTrailingSpace(leHistory),
//   };
// };

// const fetchHistoryOfLesUnderDiffDlsFailed = (errorMessage) => {
//   return {
//     type: leActionTypes.FETCH_HISTORY_OF_LES_UNDER_DIFF_DLS_FAILED,
//     payload: errorMessage,
//   };
// };

export const fetchLesHistoryStartAsync = (district) => {
  const token = window.localStorage.getItem('token');
  if (Array.isArray(district)) {
    return (dispatch) => {
      let lesHistory = [];
      dispatch(fetchLesHistoryStart());
      district.forEach((dist) => {
        fetch(`${API_URL}/dashboard/${dist}`, {
          method: 'get',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: token,
          },
        })
          .then((resp) => resp.json())
          .then((leHistory) => {
            lesHistory = lesHistory.concat(leHistory);
            lesHistory[0].LEID && dispatch(fetchLesHistorySuccess(lesHistory));
          })
          .catch((err) => dispatch(fetchLesHistoryFailed(err.errorMessage)));
      });
    };
  } else {
    return async (dispatch) => {
      dispatch(fetchLesHistoryStart());
      await fetch(`${API_URL}/dashboard/${district}`, {
        method: 'get',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: token,
        },
      })
        .then((resp) => resp.json())
        .then((leHistory) => {
          leHistory[0].LEID && dispatch(fetchLesHistorySuccess(leHistory));
        })
        .catch((err) => dispatch(fetchLesHistoryFailed(err.errorMessage)));
    };
  }
};

//Fetch individual le profile

const fetchLeProfileSuccess = (leProfile) => {
  return {
    type: leActionTypes.FETCH_LE_PROFILE_SUCCESS,
    payload: leProfile,
  };
};

const fetchLeProfileFailed = (errorMessage) => {
  return {
    type: leActionTypes.FETCH_LE_PROFILE_FAILED,
    payload: errorMessage,
  };
};

export const fetchLeProfileStartAsync = (leid) => {
  const token = window.localStorage.getItem('token');

  return async (dispatch) => {
    if (token) {
      if (leid) {
        return await fetch(`${API_URL}/profile/${leid}`, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        })
          .then((resp) => resp.json())
          .then((leProfile) => {
            if (leProfile) {
              dispatch(fetchLeProfileSuccess(removeTrailingSpace(leProfile)));
            }
          })
          .catch((err) => dispatch(fetchLeProfileFailed(err.message)));
      }
    }
  };
};

//Fetch profiles of all les

const fetchLeProfilesStart = () => {
  return {
    type: leActionTypes.FETCH_LE_PROFILES_START,
  };
};

const fetchLeProfilesSuccess = (leProfiles) => {
  return {
    type: leActionTypes.FETCH_LE_PROFILES_SUCCESS,
    payload: leProfiles,
  };
};

const fetchLeProfilesFailed = (errorMessage) => {
  return {
    type: leActionTypes.FETCH_LE_PROFILES_FAILED,
    payload: errorMessage,
  };
};

export const fetchLeProfilesStartAsync = () => {
  const token = window.localStorage.getItem('token');

  return async (dispatch) => {
    if (token) {
      dispatch(fetchLeProfilesStart());

      return await fetch(`${API_URL}/profiles`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      })
        .then((resp) => resp.json())
        .then((leProfiles) => {
          if (leProfiles) {
            dispatch(fetchLeProfilesSuccess(removeTrailingSpace(leProfiles)));
          }
        })
        .catch((err) => dispatch(fetchLeProfilesFailed(err.message)));
    }
  };
};

export const fetchUserAsync = (leid) => {
  const token = window.localStorage.getItem('token');

  return (dispatch) => {
    fetch(`${API_URL}/user/${leid}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then((resp) => resp.json())
      .then((user) => {
        dispatch({ type: leActionTypes.LE_FETCH_USER, payload: user });
      })
      .catch((err) => console.log(err.message));
  };
};
