import { accountSummaryActionTypes } from './account-summary.action-types';

const initialState = {
  isAccountSummaryFetching: false,
  accountSummary: null,
  errorMessage: null,
};

const accountSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case accountSummaryActionTypes.FETCH_ACCOUNT_SUMMARY_START:
      return {
        ...state,
        isAccountSummaryFetching: true,
      };

    case accountSummaryActionTypes.FETCH_ACCOUNT_SUMMARY_SUCCESS:
      return {
        ...state,
        accountSummary: action.payload,
        isAccountSummaryFetching: false,
      };

    case accountSummaryActionTypes.FETCH_ACCOUNT_SUMMARY_FAILED:
      return {
        ...state,
        accountSummary: action.payload,
        isAccountSummaryFetching: false,
      };

    default:
      return state;
  }
};

export default accountSummaryReducer;
