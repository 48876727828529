import { createSelector } from 'reselect';
import { setLeids } from '../../components/utils/misc';

const currentYear = new Date().getFullYear().toString();
const prevYear = (currentYear - 1).toString();

export const selectLe = (state) => state.le;

export const selectLeSales = (state) => state.leSales;

//select is individual lehistory
export const selectLeHistory = createSelector([selectLe], (le) => (le.leHistory ? le.leHistory : []));

//select all le'shistory in a district
export const selectLesHistory = createSelector([selectLe], (le) => {
  if (le.lesHistory) {
    return le.lesHistory.filter(
      (leHistory, i, self) => self.findIndex((history) => history.LEID.toString() === leHistory.LEID.toString()) === i
    );
  }

  return [];
});

//select all le'shistory in a district of current Year
export const selectLesHistoryCurrentYear = createSelector([selectLe], (le) => {
  let lesHistoryCurrentYear = le.lesHistory ? le.lesHistory.filter((le) => le.fiscyr === currentYear) : [];

  if (lesHistoryCurrentYear.length === 0) {
    lesHistoryCurrentYear = le.lesHistory ? le.lesHistory.filter((le) => le.fiscyr === prevYear) : [];
  }

  return lesHistoryCurrentYear;
});

export const selectCurrentLeHistory = createSelector([selectLeHistory], (leHistory) => {
  let currentyearLeHistory = leHistory.filter((leData) => leData.fiscyr === currentYear);
  if (currentyearLeHistory.length === 0) {
    currentyearLeHistory = leHistory.filter((leData) => leData.fiscyr === prevYear);
  }

  return currentyearLeHistory[0];
});

export const selectLeSalesStats = createSelector([selectLeSales], (leSales) =>
  leSales.leSalesStats ? leSales.leSalesStats : []
);

//select is individual lehistory fetching
export const selectIsLeFetching = createSelector([selectLe], (le) => le.isLeHistoryFetching);
//select all le'shistory in a district fetching
export const selectIsLesFetching = createSelector([selectLe], (le) => le.isLesHistoryFetching);

export const selectIsLeSalesStatsFetching = createSelector(
  [selectLeSales],
  (leSales) => leSales.isLeSalesStatsFetching
);

export const selectYearToDateCommission = createSelector([selectCurrentLeHistory], (leHistory) => {
  if (leHistory) {
    const { commission_ytd } = leHistory;

    return (Math.round(commission_ytd * 100) / 100).toFixed(2);
  }
});

//calculates commission-and-bonus-year-to-date from each le's history data
export const selectCommissionAndBonusYearToDate = createSelector([selectCurrentLeHistory], (leHistory) => {
  if (leHistory) {
    const { commissions_ytd, basic_ytd, bonus2Per_ytd, bonus3year_ytd, licensed_ytd } = leHistory;
    const totalCommissionAndBounus = Number(commissions_ytd + basic_ytd + bonus2Per_ytd + bonus3year_ytd, licensed_ytd);

    return ((totalCommissionAndBounus * 100) / 100).toFixed(2);
  }
});

//returns month-to-date sales of individual le's

export const selectMonthToDateSales = createSelector([selectCurrentLeHistory], (leHistory) => {
  if (leHistory) {
    const date = new Date();
    const currentMonth = date.toLocaleString('default', { month: 'short' });
    const month = `sales_${currentMonth}`;
    const monthToDateSales = leHistory[month.toLowerCase()];

    return monthToDateSales.toFixed(2);
  }
});

// //returns month-to-date sales of all the le's under same district
// export const selectMonthToDateSalesOfLesFromSameDistrict = createSelector(
//   [selectLesHistoryCurrentYear],
//   leHistoryCurrentYearArray => {
//     const monthToDateSalesArray = leHistoryCurrentYearArray.map(leHistory => {
//         const date = new Date();
//         const currentMonth = date.toLocaleString("default", { month: "short" });
//         const month = `sales_${currentMonth}`;
//         const monthToDateSales = leHistory[month.toLowerCase()];
//         return monthToDateSales;
//     })
//     const totalMonthToDateSalesOfAllLes = monthToDateSalesArray.reduce((acc, monthToDateSale) => {
//       acc += monthToDateSale;
//       return acc;
//     }, 0)
//     return totalMonthToDateSalesOfAllLes.toFixed(2);
//   }
// )

//returns last-month-to-date sales of all the le's under same district
// export const selectLastMonthToDateSalesOfLesFromSameDistrict = createSelector(
//   [selectLesHistoryCurrentYear],
//   leHistoryCurrentYearArray => {
//     const lastMonthToDateSalesArray = leHistoryCurrentYearArray.map(leHistory => {
//         const today = new Date();
//         const lastMonthInNo = today.getMonth() - 1;
//         let lastMonthDate = new Date(today.setMonth(lastMonthInNo));

//         const lastMonth = lastMonthDate.toLocaleString("default", {
//           month: "short",
//         });
//         const month = `sales_${lastMonth}`;
//         const lastMonthSales = leHistory[month.toLowerCase()];
//         return lastMonthSales;
//     })
//     const totalLastMonthToDateSalesOfAllLes = lastMonthToDateSalesArray.reduce((acc, monthToDateSale) => {
//       acc += monthToDateSale;
//       return acc;
//     }, 0)
//     return totalLastMonthToDateSalesOfAllLes.toFixed(2);
//   }
// )

export const selectPrevMonthSales = createSelector([selectCurrentLeHistory], (leHistory) => {
  if (leHistory) {
    const today = new Date();
    const lastMonthInNo = today.getMonth() - 1;
    let lastMonthDate = new Date(today.setMonth(lastMonthInNo));
    const lastMonth = lastMonthDate.toLocaleString('default', {
      month: 'short',
    });
    const month = `sales_${lastMonth}`;
    const lastMonthSales = leHistory[month.toLowerCase()];

    return lastMonthSales.toFixed(2);
  }
});

//select year-to-date-sales of individual LE

export const selectYearToDateSales = createSelector([selectCurrentLeHistory], (leHistory) => {
  if (leHistory) {
    const {
      sales_jan,
      sales_feb,
      sales_mar,
      sales_apr,
      sales_may,
      sales_jun,
      sales_jul,
      sales_aug,
      sales_sep,
      sales_oct,
      sales_nov,
      sales_dec,
    } = leHistory;
    const totalMonthToDateSales = Number(
      sales_jan +
        sales_feb +
        sales_mar +
        sales_apr +
        sales_may +
        sales_jun +
        sales_jul +
        sales_aug +
        sales_sep +
        sales_oct +
        sales_nov +
        sales_dec
    );

    return ((totalMonthToDateSales * 100) / 100).toFixed(2);
  }
});

//select year-to-date-sales of all LE's under same district

// export const selectYearToDateSalesOfLesFromSameDistrict = createSelector(
//   [selectLesHistoryCurrentYear],
//   lesHistoryCurrentYearArray => {
//     const yearToDateSalesArray = lesHistoryCurrentYearArray.map(leHistory => {
//         const {
//           sales_jan,
//           sales_feb,
//           sales_mar,
//           sales_apr,
//           sales_may,
//           sales_jun,
//           sales_jul,
//           sales_aug,
//           sales_sep,
//           sales_oct,
//           sales_nov,
//           sales_dec,
//         } = leHistory;
//         const totalMonthToDateSales = Number(
//           sales_jan +
//             sales_feb +
//             sales_mar +
//             sales_apr +
//             sales_may +
//             sales_jun +
//             sales_jul +
//             sales_aug +
//             sales_sep +
//             sales_oct +
//             sales_nov +
//             sales_dec
//         );
//         return ((totalMonthToDateSales * 100) / 100);
//     })
//     const totalYearToDateSales = yearToDateSalesArray.reduce((acc, yearToDateSale) => {
//       acc = acc += yearToDateSale
//       return acc;
//     },0)
//     return totalYearToDateSales.toFixed(2);
//   }
// );

//select le profile
export const selectLeProfile = createSelector([selectLe], (le) => (le ? le.profile : []));

//select le user
export const selectLeUser = createSelector([selectLe], (le) => (le ? le.user : {}));

export const selectLeLoader = createSelector([selectLe], (le) => (le ? le.loading : true));

//select WTD sales from leProfile
export const selectWTDsalesFromLeProfile = createSelector([selectLeProfile], (leProfile) => {
  let weekToDateSales = 0;
  if (leProfile) {
    weekToDateSales = Number(leProfile.User2);
  }

  return weekToDateSales;
});

//Select MTD sales from leProfile
export const selectMTDsalesFromLeProfile = createSelector([selectLeProfile], (leProfile) => {
  let monthToDateSales = 0;
  if (leProfile) {
    monthToDateSales = Number(leProfile.User3);
  }

  return monthToDateSales;
});

//select YTD sales from leProfile
export const selectYTDsalesFromLeProfile = createSelector([selectLeProfile], (leProfile) => {
  let yearToDateSales = 0;
  if (leProfile) {
    yearToDateSales = Number(leProfile.User4);
  }

  return yearToDateSales;
});

export const selectIsLeProfileLoaded = createSelector([selectLe], (le) => !!le.profile);

//select all le profiles
export const selectLeProfiles = createSelector([selectLe], (le) => (le ? le.profiles : []));

//select active le profile
export const selectActiveLeProfile = createSelector([selectLeProfiles], (profiles) =>
  profiles ? profiles.filter((profile) => profile.le_status.toLowerCase() === 'active') : []
);

//select all les of given district
export const selectLEProfilesByDistrict = (district) =>
  createSelector([selectLeProfiles], (leProfiles) => {
    if (Array.isArray(district)) {
      const leProfilesUnderDLs = district.reduce((acc, dist) => {
        const profiles = leProfiles.filter(
          (leProfile) => leProfile.district === dist && leProfile.leader_status.toLowerCase() !== 'publishing director'
        );
        acc = acc.concat(profiles);

        return acc;
      }, []);

      return leProfilesUnderDLs;
    } else {
      const leProfilesByDistrict = leProfiles.filter(
        (leProfile) =>
          leProfile.district === district && leProfile.leader_status.toLowerCase() !== 'publishing director'
      );
      const leids = leProfilesByDistrict.map((leProfile) => leProfile.LEID);
      setLeids(leids);

      return leProfilesByDistrict;
    }
  });

//selects leProfile (leProfile) and retuns currentCommisionPercentange for current Month
export const selectCurrentMonthCommission = createSelector(
  [selectLeProfile],
  (leProfile) => leProfile && leProfile.currcommpct
);

//selects leProfile (leProfile) and retuns nextCommisionPercentange for next Month
export const selectNextMonthCommission = createSelector(
  [selectLeProfile],
  (leProfile) => leProfile && leProfile.nextcommpct
);

//selects leProfile (leProfile) and retuns currentCommisionPercentange for current Month
export const selectCommissionCurrentYear = createSelector([selectLeProfile], (leProfile) =>
  leProfile ? leProfile.commissioncurryr : []
);

//selects leProfile (leProfile) and retuns nextCommisionPercentange for next Month
export const selectCommissionNextYear = createSelector(
  [selectLeProfile],
  (leProfile) => leProfile && leProfile.commissionnextyr
);

//select le written off percentage i.e. WriteOffPercent column from le_profiles

export const selectLeWrittenOffPct = createSelector(
  [selectLeProfile],
  (leProfile) => leProfile && leProfile.WriteOffPercent
);

//selects leProfiles of same district and retuns nextCommision Average Percentange for next Month
export const selectNextMonthAvgCommissionPctOfLesFromSameDistrict = (district) =>
  createSelector([selectLEProfilesByDistrict(district)], (leProfiles) => {
    let totalCommisionAvgRatePct;
    if (leProfiles) {
      const filterActiveLesProfile = leProfiles.filter(
        (profile) => profile.le_status.toLowerCase() === 'active' && profile.LEID_Name.toLowerCase() !== 'hhes'
      );
      const totalCommission = filterActiveLesProfile.reduce((acc, leProfile) => {
        acc += leProfile.nextcommpct;

        return acc;
      }, 0);
      totalCommisionAvgRatePct = totalCommission / filterActiveLesProfile.length;

      return Math.round(totalCommisionAvgRatePct);
    }

    return;
  });

// calculate Week To Date sales of les from same district
export const selectWeekToDateSalesOfLesFromSameDistrict = (district) =>
  createSelector([selectLEProfilesByDistrict(district)], (leProfilesByDistrict) => {
    let totalWeekToDateSalesOfAllLes = 0;

    if (leProfilesByDistrict) {
      totalWeekToDateSalesOfAllLes = leProfilesByDistrict.reduce((acc, leProfile) => {
        const { User2 } = leProfile;
        let wtdSalesAmt = Number(User2);
        acc += wtdSalesAmt;

        return acc;
      }, 0);

      return totalWeekToDateSalesOfAllLes;
    }
  });

// calculate Month To Date sales of les from same district
export const selectMTDSalesOfLesFromSameDistrict = (district) =>
  createSelector([selectLEProfilesByDistrict(district)], (leProfilesByDistrict) => {
    let totalMonthToDateSalesOfAllLes = 0;

    if (leProfilesByDistrict) {
      totalMonthToDateSalesOfAllLes = leProfilesByDistrict.reduce((acc, leProfile) => {
        const { User3 } = leProfile;
        let mtdSalesAmt = Number(User3);
        acc += mtdSalesAmt;

        return acc;
      }, 0);

      return totalMonthToDateSalesOfAllLes;
    }
  });

// calculate Year To Date sales of les from same district
export const selectYTDSalesOfLesFromSameDistrict = (district) =>
  createSelector([selectLEProfilesByDistrict(district)], (leProfilesByDistrict) => {
    let totalYearToDateSalesOfAllLes = 0;

    if (leProfilesByDistrict) {
      totalYearToDateSalesOfAllLes = leProfilesByDistrict.reduce((acc, leProfile) => {
        const { User4 } = leProfile;
        let ytdSalesAmt = Number(User4);
        acc += ytdSalesAmt;

        return acc;
      }, 0);

      return totalYearToDateSalesOfAllLes;
    }
  });

// calculate Week To Date sales of conference
export const selectWeekToDateSalesOfConference = (districts) =>
  createSelector([selectLEProfilesByDistrict(districts)], (leProfiles) => {
    return leProfiles.reduce((acc, leProfile) => {
      const { User2 } = leProfile;
      let WTDSalesAmt = Number(User2);
      acc += WTDSalesAmt;

      return acc;
    }, 0);
  });

// calculate Week To Date sales of conference
export const selectMonthToDateSalesOfConference = (districts) =>
  createSelector([selectLEProfilesByDistrict(districts)], (leProfiles) => {
    return leProfiles.reduce((acc, leProfile) => {
      const { User3 } = leProfile;
      let MTDSalesAmt = Number(User3);
      acc += MTDSalesAmt;

      return acc;
    }, 0);
  });

// calculate Week To Date sales of conference
export const selectYearToDateSalesOfConference = (districts) =>
  createSelector([selectLEProfilesByDistrict(districts)], (leProfiles) => {
    return leProfiles.reduce((acc, leProfile) => {
      const { User4 } = leProfile;
      let YTDSalesAmt = Number(User4);
      acc += YTDSalesAmt;

      return acc;
    }, 0);
  });
