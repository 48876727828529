export const contractActionType = {
  FETCH_CONTRACT_START: ' FETCH_CONTRACT_START',
  FETCH_CONTRACT_SUCCESS: 'FETCH_CONTRACT_SUCCESS',
  FETCH_CONTRACT_FAILED: 'FETCH_CONTRACT_FAILED',

  SET_CUSTOMER_INFO: 'SET_CUSTOMER_INFO',
  RESET_CUSTOMER_INFO: 'RESET_CUSTOMER_INFO',

  GET_CONTRACT_START: 'GET_CONTRACT_START',
  GET_CONTRACT_SUCCESS: 'GET_CONTRACT_SUCCESS',
  GET_CONTRACT_FAIL: 'GET_CONTRACT_FAIL',

  VIEW_UPDATE_ORDER_START: 'VIEW_UPDATE_ORDER_START',
  VIEW_UPDATE_ORDER_SUCCESS: 'VIEW_UPDATE_ORDER_SUCCESS',
  VIEW_UPDATE_ORDER_FAILURE: 'VIEW_UPDATE_ORDER_FAILURE',

  GET_BOOK_OORDER_START: 'GET_BOOK_OORDER_START',
  GET_BOOK_ORDER_SUCCESS: 'GET_BOOK_ORDER_SUCCESS',
  GET_BOOK_ORDER_FAILURE: 'GET_BOOK_ORDER_FAILURE',

  VIEW_PAYMENT_START: 'VIEW_PAYMENT_START',
  VIEW_PAYMENT_SUCCESS: 'VIEW_PAYMENT_SUCCESS',
  VIEW_PAYMENT_FAILURE: 'VIEW_PAYMENT_FAILURE',

  VIEW_PDFLINK_START: 'VIEW_PDFLINK_START',
  VIEW_PDFLINK_SUCCESS: 'VIEW_PDFLINK_SUCCESS',
  VIEW_PDFLINK_FAILURE: 'VIEW_PDFLINK_FAILURE',

  VIEW_REFERENCE_START: 'VIEW_REFERENCE_START',
  VIEW_REFERENCE_SUCCESS: 'VIEW_REFERENCE_SUCCESS',
  VIEW_REFERENCE_FAILURE: 'VIEW_REFERENCE_FAILURE',

  VIEW_PDF: 'VIEW_PDF',

  MARK_AS_PAID_START: 'MARK_AS_PAID_START',
  MARK_AS_PAID_SUCCESS: 'MARK_AS_PAID_SUCCESS',
  MARK_AS_PAID_FAILURE: 'MARK_AS_PAID_FAILURE',

  DELETE_ORDER_START: 'DELETE_ORDER_START',
  DELETE_ORDER_SUCCESS: 'DELETE_ORDER_SUCCESS',
  DELETE_ORDER_FAILURE: 'DELETE_ORDER_FAILURE',

  CC_POST_START: 'CC_POST_START',
  CC_POST_SUCCESS: 'CC_POST_SUCCESS',
  CC_POST_SUCCESS_STATUS: 'CC_POST_SUCCESS_STATUS',
  CC_POST_FAILURE_STATUS: 'CC_POST_FAILURE_STATUS',
  CC_POST_FAILURE: 'CC_POST_FAILURE',

  START_LOADER: 'START_LOADER',
  STOP_LOADER: 'STOP_LOADER',

  SHOW_ALERT: 'SHOW_ALERT',
  HIDE_ALERT: 'HIDE_ALERT',

  POST_UPDATE_ORDER_START: 'POST_UPDATE_ORDER_START',
  POST_UPDATE_ORDER_SUCCESS: 'POST_UPDATE_ORDER_SUCCESS',
  POST_UPDATE_ORDER_FAILED: 'POST_UPDATE_ORDER_FAILED',

  POST_BOOKS_ORDER_START: 'POST_BOOKS_ORDER_START',
  POST_BOOKS_ORDER_SUCCESS: 'POST_BOOKS_ORDER_SUCCESS',
  POST_BOOKS_ORDER_FAILED: 'POST_BOOKS_ORDER_FAILED',

  FETCH_COMPLETED_ORDER_START: 'FETCH_COMPLETED_ORDER_START',
  FETCH_COMPLETED_ORDER_SUCCESS: 'FETCH_COMPLETED_ORDER_SUCCESS',
  FETCH_COMPLETED_ORDER_FAILED: 'FETCH_COMPLETED_ORDER_FAILED',

  CANCEL_SUBSCRIPTIONS_START: 'CANCEL_SUBSCRIPTIONS_START',
  CANCEL_SUBSCRIPTIONS_SUCCESS: 'CANCEL_SUBSCRIPTIONS_SUCCESS',
  CANCEL_SUBSCRIPTIONS_FAILED: 'CANCEL_SUBSCRIPTIONS_FAILED',
};
