import { createSelector } from 'reselect';

const selectLeads = (state) => state.leads;

export const selectLeadsData = createSelector([selectLeads], (leadsData) => (leadsData.leads ? leadsData.leads : []));

export const selectIsLeadsFetching = createSelector([selectLeads], (leadsData) => leadsData.isLeadsFetching);

export const selectLeadActionMode = createSelector([selectLeads], (leadsData) => leadsData.mode);

export const selectLeadData = createSelector([selectLeads], (data) => (data.leadData ? data.leadData : ''));

export const selectLeadDataByConfID = (state) => {
  const { district } = state.user.currentUser;
  const { leads } = state.leads;
  if (leads) {
    if (district === '410' || district === '430' || district === '480') {
      return leads.filter((lead) => lead.conf_id === '400');
    } else if (district === '210' || district === '220') {
      return leads.filter((lead) => lead.conf_id === '200');
    } else if (district === '510') {
      return leads.filter((lead) => lead.conf_id === '500');
    } else if (district === '110') {
      return leads.filter((lead) => lead.conf_id === '100');
    } else {
      return leads.filter((lead) => lead.conf_id === '900');
    }
  }

  return;
};

export const selectMapDistrictToConfID = (state) => {
  const { district } = state.user.currentUser;
  if (district) {
    switch (district) {
      case '110':
        return '100';
      case '410':
        return '400';
      case '430':
        return '400';
      case '480':
        return '400';
      case '210':
        return '200';
      case '220':
        return '200';
      case '510':
        return '500';
      default:
        return '900';
    }
  }

  return;
};
