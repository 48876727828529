import { pushSubsTypes } from './push-subs.type';

const INITIAL_STATE = {
  isSubsFetching: false,
  userSubs: null,
  errorMessage: null,
};

const pushSubsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case pushSubsTypes.FETCH_PUSH_SUBS_PENDING:
      return {
        ...state,
        isSubsFetching: true,
      };
    case pushSubsTypes.FETCH_PUSH_SUBS_SUCCESS:
      return {
        ...state,
        userSubs: action.payload,
        isSubsFetching: false,
      };
    case pushSubsTypes.FETCH_PUSH_SUBS_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isSubsFetching: false,
      };
    default: {
      return state;
    }
  }
};

export default pushSubsReducer;
