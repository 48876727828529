import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { deleteLeadAsync } from '../../redux/leads/leads.action';
import { selectLeadData } from '../../redux/leads/leads.selector';
import { closeModal } from '../../redux/modal/modal.action';
import {
  selectModalActionFunc,
  selectModalActionMode,
  selectModalContent,
  selectShowModal,
} from '../../redux/modal/modal.selector';
import CustomButton from '../custom-button/custom-button';

import './modal.styles.css';

const ModalPopup = (props) => {
  const { showModal, actionMode, modalContent, dispatch, leadData, modalActionFunc } = props;

  return (
    showModal && (
      <div className="popup-container" onClick={() => dispatch(closeModal())}>
        <div className="modalStyle">
          <span className="modalCloseBtnStyle" onClick={() => dispatch(closeModal())}>
            &#10006;
          </span>
          <div className="modal-content">{modalContent}</div>
          {actionMode === 'confirm' && (
            <div className="actions">
              <div className="btn-yes">
                <CustomButton inverted>Cancel</CustomButton>
              </div>
              <div className="btn-no">
                <CustomButton
                  bgColor="rgb(250, 48, 48)"
                  onClick={() => {
                    modalActionFunc(leadData);
                    dispatch(closeModal());
                  }}
                >
                  Delete
                </CustomButton>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};

const mapStateToProps = createStructuredSelector({
  showModal: selectShowModal,
  actionMode: selectModalActionMode,
  modalContent: selectModalContent,
  modalActionFunc: selectModalActionFunc,
  leadData: selectLeadData,
});

export default connect(mapStateToProps)(ModalPopup);
