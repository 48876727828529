import React from 'react';

import './custom-button.styles.css';

const CustomButton = ({
  children,
  toggleButton,
  bgColor,
  inverted,
  left,
  right,
  leadsOnly,
  disabled,
  ...otherButtonProps
}) => {
  return (
    <button
      className={`
                ${left ? 'left' : ''}
                ${right ? 'right' : ''}
                ${inverted ? 'inverted' : ''}
                ${leadsOnly ? 'leads-only' : ''}
                ${disabled ? 'disabled-btn' : ''}
                custom-button
                `}
      style={{ backgroundColor: `${bgColor ? bgColor : '#004ED0'}` }}
      disabled={disabled ? true : false}
      {...otherButtonProps}
    >
      {children}
    </button>
  );
};

export default CustomButton;
