import { orderActionTypes } from './order.type';

const INITIAL_STATE = {
  isOrderFetching: false,
  orderDetails: null,
  errorMessage: undefined,
};

const orderDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case orderActionTypes.FETCH_ORDER_DETAILS_START:
      return {
        ...state,
        isOrderFetching: true,
      };
    case orderActionTypes.FETCH_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload,
        isOrderFetching: false,
      };
    case orderActionTypes.FETCH_ORDER_DETAILS_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isOrderFetching: false,
      };

    default:
      return state;
  }
};

export default orderDetailsReducer;
