export const productActionTypes = {
  FETCH_PRODUCT_START: 'FETCH_PRODUCT_START',
  FETCH_PRODUCT_SUCCESS: 'FETCH_PRODUCT_SUCCESS',
  FETCH_PRODUCT_FAILED: 'FETCH_PRODUCT_FAILED',

  UPDATE_PRODUCT_START: 'UPDATE_PRODUCT_START',
  UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
  UPDATE_PRODUCT_FAILED: 'UPDATE_PRODUCT_FAILED',

  POST_PRODUCT_START: 'POST_PRODUCT_START',
  POST_PRODUCT_SUCCESS: 'POST_PRODUCT_SUCCESS',
  POST_PRODUCT_FAILED: 'POST_PRODUCT_FAILED',

  DELETE_PRODUCT_START: 'DELETE_PRODUCT_START',
  DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
  DELETE_PRODUCT_FAILED: 'DELETE_PRODUCT_FAILED',

  HIDE_ALERT: 'HIDE_ALERT',
};
