export const leActionTypes = {
  LE_FETCH_START: 'LE_FETCH_START',
  LE_FETCH_SUCCESS: 'LE_FETCH_SUCCESS',
  LE_FETCH_FAILED: 'LE_FETCH_FAILED',
  LES_FETCH_START: 'LES_FETCH_START',
  LES_FETCH_SUCCESS: 'LES_FETCH_SUCCESS',
  LES_FETCH_FAILED: 'LES_FETCH_FAILED',
  FETCH_LE_PROFILE_SUCCESS: 'FETCH_LE_PROFILE_SUCCESS',
  FETCH_LE_PROFILE_FAILED: 'FETCH_LE_PROFILE_FAILED',
  FETCH_LE_PROFILES_START: 'FETCH_LE_PROFILES_START',
  FETCH_LE_PROFILES_SUCCESS: 'FETCH_LE_PROFILES_SUCCESS',
  FETCH_LE_PROFILES_FAILED: 'FETCH_LE_PROFILES_FAILED',
  FETCH_HISTORY_OF_LES_UNDER_DIFF_DLS_START: 'FETCH_HISTORY_OF_LES_UNDER_DIFF_DLS_START',
  FETCH_HISTORY_OF_LES_UNDER_DIFF_DLS_SUCCESS: 'FETCH_HISTORY_OF_LES_UNDER_DIFF_DLS_SUCCESS',
  FETCH_HISTORY_OF_LES_UNDER_DIFF_DLS_FAILED: 'FETCH_HISTORY_OF_LES_UNDER_DIFF_DLS_FAILED',
  LE_FETCH_USER: 'LE_FETCH_USER',
};
