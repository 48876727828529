import { createSelector } from 'reselect';

const selectModal = (state) => state.modal;

export const selectShowModal = createSelector([selectModal], (selectModal) => selectModal.showModal);

export const selectModalContent = createSelector([selectModal], (selectModal) => selectModal.modalContent);

export const selectModalActionMode = createSelector([selectModal], (selectModal) => selectModal.actionMode);
export const selectModalActionFunc = createSelector([selectModal], (selectModal) => selectModal.actionFunc);
