//returns browser info with name and version object
export const browser = () => {
  var userAgent = navigator.userAgent,
    match = userAgent.match(/(opera|chrome|crios|safari|ucbrowser|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [],
    result = {},
    tem;

  if (/trident/i.test(match[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
    result.name = 'Internet Explorer';
  } else if (match[1] === 'Chrome') {
    tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);

    if (tem && tem[1]) {
      result.name = tem[0].indexOf('Edge') === 0 ? 'Edge' : 'Opera';
    }
  }
  if (!result.name) {
    tem = userAgent.match(/version\/(\d+)/i); // iOS support
    result.name = match[0].replace(/\/.*/, '');

    if (result.name.indexOf('MSIE') === 0) {
      result.name = 'Internet Explorer';
    }
    if (userAgent.match('CriOS')) {
      result.name = 'Chrome';
    }
  }
  if (tem && tem.length) {
    match[match.length - 1] = tem[tem.length - 1];
  }

  result.version = Number(match[match.length - 1]);

  return result;
};

// this function uses reg exp to replace all the spaces and nonalphanumeric chars with '-'
export const routeGenerator = (menuData) => {
  const { menuName, route } = menuData;
  console.log();

  return route.includes('http') ? route : encodeURI(menuName.replace(/\W+/g, '-').toLowerCase());
};

//can perform certain actions depending upton different routes
export const onRouteChange = (route, setCurrentUser, history) => {
  switch (route) {
    case 'signout':
      window.localStorage.removeItem('token');

      return setCurrentUser(null);
    case '/dashboard':
      return history.push('/dashboard');
    default:
      return;
  }
};

//get current screen width
export const getScreenWidth = () => {
  return window.innerWidth;
};

//calculate sub total amount

export const calcSubTotal = (booksData) => {
  return booksData.reduce((accumulator, book) => {
    const { price, quantity } = book;
    accumulator = accumulator + quantity * price;

    return accumulator;
  }, 0);
};

//random quotes generator

export const randomQuoteGenerator = (quoteArray) => {
  const quoteIndex = Math.floor(Math.random() * quoteArray.length);

  return quoteArray[quoteIndex];
};

//trim database query response object and remove trailing white spaces, also return data as it is if the data is other than string.
export const removeTrailingSpace = (dataToBeTrimmed) => {
  if (Array.isArray(dataToBeTrimmed)) {
    dataToBeTrimmed.map((d) => {
      const trimmedData = Object.keys(d).map((k) => (d[k] = typeof d[k] === 'string' ? d[k].trim() : d[k]));

      return trimmedData;
    });
  } else if (typeof dataToBeTrimmed === 'string') {
    return dataToBeTrimmed.trim();
  } else {
    const data = dataToBeTrimmed;
    Object.keys(data).map((k) => (data[k] = typeof data[k] === 'string' ? data[k].trim() : data[k]));

    return data;
  }

  return dataToBeTrimmed;
};

//Returns first and last name initials.
export const getFirstAndLastInitial = (name) => {
  if (name) {
    const splitName = name.split(' '); //returns an array of first and last name
    const firstAndLastInitialsArray = splitName.map((name) => {
      let i;
      let initials;
      for (i = 0; i < name.length; i++) {
        initials = name.charAt(0).toUpperCase();
      }

      return initials;
    });
    const firstAndLastInitialsString = firstAndLastInitialsArray.toString();
    const FirstAndLastInitials = firstAndLastInitialsString.replace(/,/g, '.');

    return FirstAndLastInitials;
  }

  return;
};

//Capitatlize first letter of given string and only retuns first word
export const capitatlizeFirstNameFirstLetter = (name) => {
  if (name) {
    const splitName = name.split(' '); //returns an array of first and last name
    const firstAndLastNameArray = splitName.map((name) => {
      let newName = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

      return newName;
    });
    const getFirstNameOnly = firstAndLastNameArray.splice(0, 1);

    return getFirstNameOnly.toString();
  }
};

//Capitatlize first letter of given string
export const capitatlizeFirstLetter = (name) => {
  if (name) {
    const splitName = name.split(' '); //returns an array of first and last name
    const firstAndLastNameArray = splitName.map((name) => {
      let newName = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

      return newName;
    });

    return firstAndLastNameArray.join(' ');
  }
};

// returns 1 for + amount or -1 for or - amount or 0 for 0 amount.
export const checkAmoutSign = (amount) => {
  const num = Math.sign(amount);

  return num;
};

// this function checks sign of given amount, roundup the amount and adds dollar sign infront.
export const addDollarSign = (amt) => {
  const amount = (amt * 100) / 100;
  //check if the amount is positive or negative value
  const checkamountSign = checkAmoutSign(Number(amount));

  let dollarAmt;
  let absAmount;

  if (checkamountSign >= 0 && amount % 1 !== 0) {
    dollarAmt = '$' + amount.toFixed(2);
  } else if (checkamountSign >= 0 && amount % 1 === 0) {
    dollarAmt = '$' + amount + '.00';
  } else if (checkamountSign < 0 && amount % 1 !== 0) {
    absAmount = Math.abs(amount);
    dollarAmt = '- $' + absAmount.toFixed(2);
  } else {
    absAmount = Math.abs(amount);
    dollarAmt = '- $' + absAmount + '.00';
  }

  return dollarAmt;
};

//parse number to Integer value and convert it to user readable string
// export const parseInteger = (number) => {
//     let Num = Number(number);
//     if(Number.isInteger(Num)) {
//         return parseInt(Num);
//     }
//     return parseFloat(Num);
//   }

// //converts exponential no to user readable float value
// export const convertExponentialToDecimal = (exponentialNumber) => {
//     // sanity check - is it exponential number
//     const str = exponentialNumber.toString();
//     let num;
//     let result;
//     if (str.indexOf('e') !== -1) {
//       const exponent = parseInt(str.split('-')[1], 15);
//       // Unfortunately I can not return 1e-8 as 0.00000001, because even if I call parseFloat() on it,
//       // it will still return the exponential representation
//       // So I have to use .toFixed()
//       if(Math.sign(exponentialNumber) === 1 && Math.sign(exponentialNumber) !== 0) {
//         num = exponentialNumber.toFixed(exponent);
//         result = Number(num);
//         return parseInt(result);
//       }else {
//         num = exponent;
//         result = parseInt(num)
//         return result.toFixed(2)
//       }
//     }else {
//         return exponentialNumber.toFixed(2);
//     }
// }

export const convertExponentialToDecimal = (exponentialNumber) => {
  // sanity check - is it exponential number
  const str = exponentialNumber.toString();
  if (str.indexOf('e') !== -1) {
    const exponent = parseInt(str.split('-')[1], 14); // Unfortunately I can not return 1e-8 as 0.00000001, because even if I call parseFloat() on it, // it will still return the exponential representation // So I have to use .toFixed()
    const result = exponentialNumber.toFixed(exponent);

    return Math.round(result);
  } else {
    return exponentialNumber;
  }
};

export const mapMonthToString = (monthNo) => {
  if (monthNo) {
    const month = [];
    month[1] = 'January';
    month[2] = 'February';
    month[3] = 'March';
    month[4] = 'April';
    month[5] = 'May';
    month[6] = 'June';
    month[7] = 'July';
    month[8] = 'August';
    month[9] = 'September';
    month[10] = 'October';
    month[11] = 'November';
    month[12] = 'December';

    return month[monthNo];
  }
};

export const getCurrentCommissionMonth = (data) => {
  const newData = data.trim();
  const getMonthNum = newData.substr(4, 2) * 1;
  const month = mapMonthToString(getMonthNum);

  return month;
};

const STORAGE_KEY = 'leids';

export const setLeids = (leids, key = STORAGE_KEY) => {
  if (localStorage) {
    return localStorage.setItem(key, JSON.stringify(leids));
  }
};

export const getLeids = (key = STORAGE_KEY) => {
  if (localStorage && localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key));
  }
};

export const getCurrentYear = () => {
  const todaysDate = new Date();
  const currentYear = todaysDate.getFullYear();

  return currentYear;
};
