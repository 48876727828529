import { stateTaxMasterSetupType } from './state-tax-master-setup.type';

const INITIAL_STATE = {
  isLoading: false,
  stateList: [],
  errorMessage: null,
  isRequestingForToggle: false,
  alert: {
    severity: '',
    status: false,
    titleMessage: '',
    subMessage: '',
  },
};

const stateTaxMasterSetupReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case stateTaxMasterSetupType.FETCH_STATE_DATA_START:
      return {
        ...state,
        isLoading: true,
      };
    case stateTaxMasterSetupType.FETCH_STATE_DATA_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
        stateList: [],
      };
    case stateTaxMasterSetupType.FETCH_STATE_DATA_SUCCESS:
      return {
        ...state,
        stateList: action.payload,
        isLoading: false,
      };
    case stateTaxMasterSetupType.TOGGLE_STATE_TAX_INCLUDED_FLAG_START:
      return {
        ...state,
        isRequestingForToggle: true,
      };
    case stateTaxMasterSetupType.TOGGLE_STATE_TAX_INCLUDED_FLAG_SUCCESS:
      const index = state.stateList?.findIndex((state) => state?.id === action?.payload?.id);
      const newStateLists = [...state.stateList];
      newStateLists[index] = { ...action.payload, tax_flag: action.payload.taxFlag };

      return {
        ...state,
        isRequestingForToggle: false,
        stateList: [...newStateLists],
        alert: {
          severity: 'success',
          status: true,
          titleMessage: 'Alert',
          subMessage: 'Tax Status has been updated Succussful',
        },
      };
    case stateTaxMasterSetupType.TOGGLE_STATE_TAX_INCLUDED_FLAG_FAILED:
      return {
        ...state,
        isRequestingForToggle: false,
        alert: {
          severity: 'success',
          status: true,
          titleMessage: 'Alert',
          subMessage: 'Error Occured During Updating Tax Status',
        },
      };

    case stateTaxMasterSetupType.HIDE_ALERT:
      return {
        ...state,
        alert: {
          severity: '',
          status: false,
          titleMessage: '',
          subMessage: '',
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default stateTaxMasterSetupReducer;
