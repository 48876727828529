export default {
  root: {
    background: '#FAFBFC',

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#D4E1F2',
      },
      '&:hover fieldset': {
        borderColor: '#D4E1F2',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#D4E1F2',
      },
    },
  },
};
