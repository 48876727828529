import { productActionTypes } from './product.type';

const INITIAL_STATE = {
  isLoading: false,
  productList: [],
  errorMessage: null,
  alert: {
    severity: '',
    status: false,
    titleMessage: '',
    subMessage: '',
  },
};

const productReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case productActionTypes.FETCH_PRODUCT_START:
      return {
        ...state,
        isLoading: true,
      };
    case productActionTypes.FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        productList: action.payload,
        isLoading: false,
      };
    case productActionTypes.FETCH_PRODUCT_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      };
    case productActionTypes.POST_PRODUCT_START:
      return {
        ...state,
        isLoading: true,
      };
    case productActionTypes.POST_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        productList: [action.payload, ...state.productList],
        alert: {
          severity: 'success',
          status: true,
          titleMessage: 'Successfully Added Product',
        },
      };
    case productActionTypes.POST_PRODUCT_FAILED:
      return {
        ...state,
        isLoading: false,
        alert: {
          severity: 'error',
          status: true,
          titleMessage: 'Failed to Add Product',
        },
      };
    case productActionTypes.UPDATE_PRODUCT_START:
      return {
        ...state,
        isLoading: true,
      };
    case productActionTypes.UPDATE_PRODUCT_SUCCESS:
      const index = state.productList.findIndex((product) => product.p_id === action.payload.p_id);

      const newProductList = [...state.productList];
      newProductList[index] = action.payload;

      return {
        ...state,
        isLoading: false,
        productList: newProductList,
        alert: {
          severity: 'success',
          status: true,
          titleMessage: 'Successfully Updated Product',
        },
      };
    case productActionTypes.UPDATE_PRODUCT_FAILED:
      return {
        ...state,
        isLoading: false,
        alert: {
          severity: 'error',
          status: true,
          titleMessage: 'Failed to Update Product',
        },
      };

    case productActionTypes.DELETE_PRODUCT_START:
      return {
        ...state,
        isLoading: true,
      };
    case productActionTypes.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        alert: {
          severity: 'success',
          status: true,
          titleMessage: 'Successfully Deleted Product',
        },
      };
    case productActionTypes.DELETE_PRODUCT_FAILED:
      return {
        ...state,
        isLoading: false,
        alert: {
          severity: 'error',
          status: true,
          titleMessage: 'Failed to Delete Product',
        },
      };
    case productActionTypes.HIDE_ALERT:
      return {
        ...state,
        alert: {
          severity: '',
          status: false,
          titleMessage: '',
        },
      };
    default:
      return state;
  }
};

export default productReducers;
