import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Header from 'components/header/header';
import { selectTempUser } from 'redux/user/user.selector';

const Main = (props) => {
  const { tempUser, children } = props;
  return (
    <>
      <Header tempUser={tempUser} />
      <div style={{ padding: '50px 0' }}>{children}</div>
    </>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

const mapStateToProps = createStructuredSelector({
  tempUser: selectTempUser,
});

export default connect(mapStateToProps)(Main);
