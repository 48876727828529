export const leadsActionType = {
  FETCH_LEAD_START: ' FETCH_LEAD_START',
  FETCH_LEAD_SUCCESS: 'FETCH_LEAD_SUCCESS',
  FETCH_LEAD_FAILED: 'FETCH_LEAD_FAILED',
  FETCH_ASSIGNED_LEAD_START: ' FETCH_ASSIGNED_LEAD_START',
  FETCH_ASSIGNED_LEAD_SUCCESS: 'FETCH_ASSIGNED_LEAD_SUCCESS',
  SET_LEAD_ACTION_MODE: 'SET_LEAD_ACTION_MODE',
  ADD_LEAD: 'ADD_LEAD',
  UPDATE_LEAD: 'UPDATE_LEAD',
  DELETE_LEAD: 'DELETE_LEAD',
  RESET_LEADS: 'RESET_LEADS',
  RESET_ASSIGNED_LEADS: 'RESET_ASSIGNED_LEADS',
};
