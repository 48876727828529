import React from 'react';

import { connect } from 'react-redux';

import { Link, withRouter } from 'react-router-dom';

import { IoMdArrowDropdown, IoMdPerson, IoMdNotifications } from 'react-icons/io';

import { MdNotificationsActive } from 'react-icons/md';

import Logo from '../../assets/logo.png';

import { createStructuredSelector } from 'reselect';

import { selectIsUserLoggedIn, selectCurrentUser } from '../../redux/user/user.selector';

import { getScreenWidth, getFirstAndLastInitial, browser } from '../utils/misc';

import MenuDropdown from '../menu-dropdown/menu-dropdown';

import './header.styles.css';

import { fetchPushSubsStartAsync } from '../../redux/push-subs/push-subs.action';

import { askForNotificationPermission } from '../../utils/sw';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const webBrowser = browser();

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDropdownProfileMenu: false,
      showDropdownMenu: false,
      showResourcesMenu: false,
      showEcontractMenu: false,
      showProductAndLibraryMenu: false,
      showDropdownNotification: false,
      currentWindowWidth: '',
    };
  }

  updateCurrentWindowWidth = () => {
    this.setState({ currentWindowWidth: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateCurrentWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCurrentWindowWidth);
  }

  //toggle bellIcon

  toggleBellIcon = (tempUser) => {
    askForNotificationPermission(this.props.currentUser.leid);
  };

  //toggle dropdown menu

  toggleDropdownMenu = (
    route = undefined,

    status = undefined,

    parent = undefined
  ) => {
    const { history } = this.props;

    switch (true) {
      case route !== undefined && status === 'close':
        if (route.includes('http')) {
          window.location = route;

          return this.setState({
            showDropdownMenu: false,

            showDropdownProfileMenu: false,
          });
        }

        history.push(`${route}`);

        return this.setState({
          showDropdownMenu: false,

          showDropdownProfileMenu: false,

          showEcontractMenu: false,
          showProductAndLibraryMenu: false,
          showResourcesMenu: false,
        });

      case route === undefined && status === 'open' && parent === 'profile':
        return this.setState({
          showDropdownMenu: false,

          showDropdownProfileMenu: true,
        });

      case route === undefined && status === 'open' && parent === 'notification':
        return this.setState({
          showDropdownMenu: false,

          showDropdownProfileMenu: false,
        });

      case route === undefined && status === 'open' && parent === 'menu':
        return this.setState({
          showDropdownMenu: true,

          showDropdownProfileMenu: false,
        });

      case route === undefined && status === 'close':
        return this.setState({
          showDropdownMenu: false,

          showDropdownProfileMenu: false,

          showEcontractMenu: false,

          showResourcesMenu: false,
          showProductAndLibraryMenu: false,
        });

      case route === undefined && status === undefined && parent === 'menu':
        return this.setState({
          showDropdownMenu: !this.state.showDropdownMenu,

          showDropdownProfileMenu: false,
        });

      case route === undefined && status === undefined && parent === 'profile':
        return this.setState({
          showDropdownProfileMenu: !this.state.showDropdownProfileMenu,

          showDropdownMenu: false,
        });

      case route === undefined && status === undefined && parent === 'notification':
        return this.setState({
          showDropdownProfileMenu: false,

          showDropdownMenu: false,
        });

      case route === undefined && status === undefined && parent === 'e-contract':
        return this.setState({
          showEcontractMenu: !this.state.showEcontractMenu,
          showProductAndLibraryMenu: false,
          showDropdownMenu: false,

          showDropdownProfileMenu: false,

          showResourcesMenu: false,
        });

      case route === undefined && status === undefined && parent === 'productAndLibraryList':
        return this.setState({
          showProductAndLibraryMenu: !this.state.showProductAndLibraryMenu,
          showEcontractMenu: false,
          showDropdownMenu: false,

          showDropdownProfileMenu: false,

          showResourcesMenu: false,
        });

      case route === undefined && status === undefined && parent === 'resources':
        return this.setState({
          showResourcesMenu: !this.state.showResourcesMenu,
          showProductAndLibraryMenu: false,
          showDropdownMenu: false,

          showDropdownProfileMenu: false,

          showEcontractMenu: false,
        });

      default:
        return;
    }
  };

  render() {
    const { name, leid, leaderStatus } = this.props.currentUser;

    const { onRouteChange, isLoggedIn, tempUser } = this.props;

    const {
      showDropdownMenu,
      showEcontractMenu,
      showResourcesMenu,
      showDropdownProfileMenu,
      showProductAndLibraryMenu,
    } = this.state;

    return (
      leaderStatus && (
        <ClickAwayListener onClickAway={() => this.toggleDropdownMenu(undefined, 'close')}>
          <section className="header">
            <Link to={`${isLoggedIn ? '/dashboard' : '/'}`} className="logo">
              <img src={`${Logo}`} alt="logo" />
            </Link>

            {isLoggedIn ? (
              <div
                className="menu-options-container"
                style={{
                  justifyContent: `${getScreenWidth() < 1020 ? 'flex-end' : 'center'}`,
                }}
              >
                <div className="desktop-menu-options-container">
                  {getScreenWidth() > 1020 && (
                    <div className="express-menu-options">
                      <Link
                        to="/dashboard"
                        className="option-link"
                        onClick={() => this.toggleDropdownMenu(undefined, 'close')}
                      >
                        <span>Dashboard</span>
                      </Link>

                      {tempUser?.LEID === '4321' || tempUser?.LEID === '5092' ? null : (
                        <Link
                          to="/customers"
                          className="option-link"
                          onClick={() => this.toggleDropdownMenu(undefined, 'close')}
                        >
                          <span>Customers</span>
                        </Link>
                      )}

                      {leaderStatus.toLowerCase() === 'publishing director' && (
                        <Link
                          to="/leads"
                          className="option-link"
                          onClick={() => this.toggleDropdownMenu(undefined, 'close')}
                        >
                          <span>Leads</span>
                        </Link>
                      )}

                      {leaderStatus.toLowerCase() !== 'publishing director' && (
                        <Link
                          to="/assigned-leads"
                          className="option-link"
                          onClick={() => this.toggleDropdownMenu(undefined, 'close')}
                        >
                          <span>Assigned Leads</span>
                        </Link>
                      )}

                      <div className="dropdown-options">
                        <span
                          className="option-link"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.toggleDropdownMenu(
                              undefined,

                              undefined,

                              'e-contract'
                            )
                          }
                        >
                          E-Contract
                        </span>

                        {showEcontractMenu && (
                          <MenuDropdown
                            className="menu-dropdown"
                            toggleDropdownMenu={this.toggleDropdownMenu}
                            parent={'e-contract'}
                          />
                        )}
                      </div>

                      {/* <a href="https://forms.logiforms.com/formdata/user_forms/77894_7282070/356775/"



                                    className="option-link"



                                    onClick={() => this.toggleDropdownMenu(undefined, 'close')}



                                >



                                    <span>E-Contract</span>



                                </a>







                                <a href="https://forms.logiforms.com///published/index.lf?mode=live&workflowid=4b9f4755-5a4a-4692-afc1-0b1262645721&usr_id=77894&pageid=128424"



                                    className="option-link"



                                    onClick={() => this.toggleDropdownMenu(undefined, 'close')}



                                >



                                    <span>E-Contract LE Portal</span>



                                </a> */}

                      {/* <Link to="/order-form"



                                    className="option-link"



                                    onClick={() => this.toggleDropdownMenu(undefined, 'close')}



                                >



                                    <span>Order Forms</span>



                                </Link> */}

                      <Link
                        to="/down-payment"
                        className="option-link"
                        onClick={() => this.toggleDropdownMenu(undefined, 'close')}
                      >
                        <span>Down Payment</span>
                      </Link>

                      <div className="dropdown-options">
                        <span
                          className="option-link"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.toggleDropdownMenu(
                              undefined,

                              undefined,

                              'resources'
                            )
                          }
                        >
                          Resourses
                        </span>

                        {showResourcesMenu && (
                          <MenuDropdown
                            className="menu-dropdown"
                            toggleDropdownMenu={this.toggleDropdownMenu}
                            parent={'resources'}
                          />
                        )}
                      </div>
                      {leaderStatus === 'Admin' && (
                        <div className="dropdown-options">
                          <span
                            className="option-link"
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              this.toggleDropdownMenu(
                                undefined,

                                undefined,

                                'productAndLibraryList'
                              )
                            }
                          >
                            Admin's Menu
                          </span>

                          {showProductAndLibraryMenu && (
                            <MenuDropdown
                              className="menu-dropdown"
                              toggleDropdownMenu={this.toggleDropdownMenu}
                              parent={'productAndLibraryList'}
                            />
                          )}
                        </div>
                      )}

                      {/* <Link to="/reports"



                                    className="option-link"



                                    onClick={() => this.toggleDropdownMenu(undefined, 'close')}



                                >



                                    <span>Reports</span>



                                </Link> */}

                      {/* <Link to="/leads"



                                    className="option-link"



                                    onClick={() => this.toggleDropdownMenu(undefined, 'close')}



                                >







                                    <span>Leads</span>



                                </Link> */}
                    </div>
                  )}

                  {getScreenWidth() > 1500 && (
                    <div className="full-menu-options">
                      {/* <Link to="/maps"



                                    className="option-link"



                                    onClick={() => this.toggleDropdownMenu(undefined, 'close')}



                                >



                                    <span>Maps</span>



                                </Link> */}

                      {/* <Link to="/training-and-sales-aids"



                                    className="option-link"



                                    onClick={() => this.toggleDropdownMenu(undefined, 'close')}



                                >



                                    <span>Training And Sales Aids</span>



                                </Link> */}
                    </div>
                  )}
                </div>

                {getScreenWidth() <= 1020 && (
                  <div className="tab-and-mobile-menu-container">
                    <span
                      className="option-link"
                      onClick={() => this.toggleDropdownMenu(undefined, undefined, 'menu')}
                      style={{ fontSize: '16px' }}
                    >
                      Menu
                    </span>

                    {showDropdownMenu && (
                      <MenuDropdown
                        parent={'menu'}
                        toggleDropdownMenu={this.toggleDropdownMenu}
                        onRouteChange={onRouteChange}
                        tempUser={this.props.tempUser}
                      />
                    )}
                  </div>
                )}
              </div>
            ) : null}

            {/* notification */}

            <React.Fragment>
              <div
                className="notification-icon-container"
                onClick={() => this.toggleDropdownMenu(undefined, undefined, 'notification')}
              >
                {(isLoggedIn && webBrowser.name === 'Safari') ||
                this.props.currentUser.leaderStatus !== 'NONE' ? null : (
                  <div className="bell-icon" onClick={() => this.toggleBellIcon(tempUser)}>
                    <MdNotificationsActive size={25} />
                  </div>
                )}
              </div>
            </React.Fragment>

            {/* profile */}

            <div className="sign-in-and-signout-container">
              {isLoggedIn ? (
                <span className="name">{getFirstAndLastInitial(name)}</span>
              ) : (
                <div className="login">
                  <Link to="/">Sign In</Link>

                  {/* <span className="vertical-bar">&#10072;</span>



                                <Link to="/register">Regster</Link> */}
                </div>
              )}

              {isLoggedIn && (
                <React.Fragment>
                  <div className="icon" onClick={() => this.toggleDropdownMenu(undefined, undefined, 'profile')}>
                    <span className="down-arrow">
                      <IoMdArrowDropdown />
                    </span>

                    <span className="person-icon">
                      <IoMdPerson size={25} />
                    </span>
                  </div>

                  {showDropdownProfileMenu && (
                    <MenuDropdown
                      className="menu-dropdown"
                      toggleDropdownMenu={this.toggleDropdownMenu}
                      parent={'profile'}
                      onRouteChange={onRouteChange}
                      tempUser={this.props.tempUser}
                    />
                  )}
                </React.Fragment>
              )}
            </div>
          </section>
        </ClickAwayListener>
      )
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLoggedIn: selectIsUserLoggedIn,

  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserSubs: (leid) => dispatch(fetchPushSubsStartAsync(leid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
