import { libraryActionTypes } from './library.type';

const INITIAL_STATE = {
  isLoading: false,
  libraryList: [],
  errorMessage: null,
  alert: {
    severity: '',
    status: false,
    titleMessage: '',
    subMessage: '',
  },
};

const libraryReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case libraryActionTypes.HIDE_ALERT:
      return {
        ...state,
        alert: {
          severity: '',
          status: false,
          titleMessage: '',
          subMessage: '',
        },
      };

    case libraryActionTypes.FETCH_LIBRARY_START:
      return {
        ...state,
        isLoading: true,
      };
    case libraryActionTypes.FETCH_LIBRARY_SUCCESS:
      return {
        ...state,
        libraryList: action.payload,
        isLoading: false,
      };
    case libraryActionTypes.FETCH_LIBRARY_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      };

    case libraryActionTypes.POST_LIBRARY_START:
      return {
        ...state,
        isLoading: true,
      };
    case libraryActionTypes.POST_LIBRARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        libraryList: [...state.libraryList, action.payload],
        alert: {
          severity: 'success',
          status: true,
          titleMessage: 'Successfully Added Library',
        },
      };
    case libraryActionTypes.POST_LIBRARY_FAILED:
      return {
        ...state,
        isLoading: false,
        alert: {
          severity: 'error',
          status: true,
          titleMessage: 'Failed to Add Library',
        },
      };

    case libraryActionTypes.UPDATE_LIBRARY_START:
      return {
        ...state,
        isLoading: true,
      };
    case libraryActionTypes.UPDATE_LIBRARY_SUCCESS:
      const index = state.libraryList.findIndex((library) => library.l_id === action.payload.l_id);

      const newLibraryList = [...state.libraryList];

      newLibraryList[index] = action.payload;

      return {
        ...state,
        isLoading: false,
        libraryList: newLibraryList,
        alert: {
          severity: 'success',
          status: true,
          titleMessage: 'Successfully Updated Library',
        },
      };
    case libraryActionTypes.UPDATE_LIBRARY_FAILED:
      return {
        ...state,
        isLoading: false,
        alert: {
          severity: 'error',
          status: true,
          titleMessage: 'Failed to Update Library',
        },
      };

    case libraryActionTypes.DELETE_LIBRARY_START:
      return {
        ...state,
        isLoading: true,
      };
    case libraryActionTypes.DELETE_LIBRARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        alert: {
          severity: 'success',
          status: true,
          titleMessage: 'Successfully Deleted Library',
        },
      };
    case libraryActionTypes.DELETE_LIBRARY_FAILED:
      return {
        ...state,
        isLoading: false,
        alert: {
          severity: 'error',
          status: true,
          titleMessage: 'Failed to Delete Library',
        },
      };
    default:
      return state;
  }
};

export default libraryReducers;
