export const modalActionType = {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  SET_MODAL_ACTION_MODE: 'SET_MODAL_ACTION_MODE',
};

export const openModal = (modalContent, actionMode = 'info', actionFunc) => {
  return {
    type: modalActionType.OPEN_MODAL,
    payload: {
      modalContent,
      actionMode,
      actionFunc,
    },
  };
};

export const closeModal = () => {
  return {
    type: modalActionType.CLOSE_MODAL,
  };
};

export const setModalActionMode = (mode) => {
  return {
    type: modalActionType.SET_MODAL_ACTION_MODE,
    payload: mode,
  };
};
