import { userActionTypes } from './user.type';

const INITIAL_STATE = {
  isLoggedIn: false,
  isUserFetching: false,
  errorMessage: undefined,
  tempUser: null,
  currentUser: {
    leid: '',
    name: '',
    leaderStatus: '',
    status: '',
    district: '',
    currentMonthCommission: '',
    currentMonthCommissionData: '',
    nextMonthCommission: '',
    nextMonthCommissionData: '',
    writtenOffPct: '',
    paBalance: '',
  },
};

const setCurrentUser = (user) => {
  if (user !== null && user.LEID) {
    return {
      leid: user.LEID,
      name: user.LEID_Name,
      leaderStatus: user.leader_status,
      district: user.district,
      currentMonthCommission: user.currcommpct,
      nextMonthCommission: user.nextcommpct,
      currentMonthCommissionData: user.commissioncurryr,
      nextMonthCommissionData: user.commissionnextyr,
      writtenOffPct: user.WriteOffPercent,
      status: user.le_status,
      paBalance: user.CurrentBalance,
    };
  }

  return { leid: '', name: ' ' };
};

const isUserLoggedIn = (user) => {
  return user ? true : false;
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userActionTypes.FETCH_USER_START:
      return {
        ...state,
        isUserFetching: true,
      };
    case userActionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        currentUser: setCurrentUser(action.payload),
        isLoggedIn: isUserLoggedIn(action.payload),
        isUserFetching: false,
      };
    case userActionTypes.FETCH_USER_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isUserFetching: false,
      };
    case userActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: setCurrentUser(action.payload),
        isLoggedIn: isUserLoggedIn(action.payload),
      };
    case userActionTypes.SET_TEMP_USER:
      return {
        ...state,
        tempUser: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
